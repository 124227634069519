.litepicker {
  background-color: white;
  border-radius: theme("borderRadius.lg");
  font-size: theme("fontSize.sm");
  z-index: 999999 !important;
  margin-top: 7px;
  box-shadow: 0px 3px 20px #00000014;
  border: 1px solid theme("colors.slate.200");
}

@media (max-width: calc(theme("screens.lg") - 1px)) {
  .litepicker {
    width: 310px;
  }
}

@media (max-width: calc(theme("screens.sm") - 1px)) {
  .litepicker {
    left: 0px !important;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
  }
}

.litepicker .container__months,
.litepicker .container__footer {
  box-shadow: none;
  background-color: transparent;
  padding-top: 0;
}

.litepicker .container__footer {
  border-top: 1px solid theme("colors.slate.200" / 60%);
  margin: 0;
  padding: theme("spacing.3");
}

@media (max-width: calc(theme("screens.lg") - 1px)) {
  .litepicker .container__footer {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }
}

.litepicker .container__footer .preview-date-range {
  @media (max-width: calc(theme("screens.lg") - 1px)) {
    width: 100%;
    display: block;
    order: 1;
    margin-bottom: theme("spacing.[1.5]");
  }
}

.litepicker .container__footer .button-cancel {
  @media (max-width: calc(theme("screens.lg") - 1px)) {
    width: 100% !important;
    margin-left: 0px !important;
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
}

.litepicker .container__footer .button-apply {
  @media (max-width: calc(theme("screens.lg") - 1px)) {
    width: 100% !important;
    margin-left: 0px !important;
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
    margin-bottom: theme("spacing.[1.5]");
  }
}

.litepicker .container__footer .button-apply,
.litepicker .container__footer .button-cancel {
  width: theme("spacing.20");
  padding: theme("spacing.1") theme("spacing.2");
  border-radius: theme("borderRadius.md");
  font-weight: theme("fontWeight.medium");
  margin-left: theme("spacing.1");
  margin-right: 0;
}

.litepicker .container__footer .button-apply {
  background-color: theme("colors.slate.700");
  color: white;
}

.litepicker .container__footer .button-cancel {
  background-color: theme("colors.slate.200" / 70%);
  color: theme("colors.slate.500");
}

.litepicker .container__months {
  padding-left: theme("spacing.1");
  padding-right: theme("spacing.1");
}

.litepicker .container__months .month-item-weekdays-row {
  color: theme("colors.slate.400");
  margin-top: theme("spacing.3");
}

.litepicker .container__months .month-item-header {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: theme("spacing.2");
}

.litepicker .container__months .month-item-header .button-previous-month,
.litepicker .container__months .month-item-header .button-next-month {
  display: flex;
  align-items: center;
  justify-content: center;
  width: theme("spacing.8");
  height: theme("spacing.8");
}

.litepicker .container__months .month-item-header .button-previous-month:hover,
.litepicker .container__months .month-item-header .button-next-month:hover {
  background-color: theme("colors.slate.100");
}

.litepicker .container__months .month-item-header .button-previous-month svg {
  transform: rotate(135deg);
  margin-right: calc(theme("spacing.1") * -1);
}

.litepicker .container__months .month-item-header .button-next-month svg {
  transform: rotate(-45deg);
  margin-left: calc(theme("spacing.1") * -1);
}

.litepicker .container__months .month-item-header .button-previous-month:hover svg,
.litepicker .container__months .month-item-header .button-next-month:hover svg,
.litepicker .container__months .month-item-header .button-previous-month svg,
.litepicker .container__months .month-item-header .button-next-month svg {
  fill: transparent;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  border-color: #4a5568;
  width: theme("spacing.2");
  height: theme("spacing.2");
  display: block;
}

.litepicker .container__months .month-item-header div > .month-item-name,
.litepicker .container__months .month-item-header div > .month-item-year {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='rgb(74, 85, 104)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='lucide lucide-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
  background-size: 15px;
  background-position: center right 0.6rem;
  background-color: transparent;
  font-size: theme("fontSize.sm");
  border-width: 1px;
  border-style: solid;
  background-repeat: no-repeat;
  padding-top: theme("spacing.1");
  padding-bottom: theme("spacing.1");
  padding-left: theme("spacing.2");
  padding-right: theme("spacing.8");
  border-radius: theme("borderRadius.md");
  border-color: theme("colors.slate.200");
  font-weight: theme("fontWeight.normal");
}

.litepicker .container__months .month-item-header div > .month-item-name:focus,
.litepicker .container__months .month-item-header div > .month-item-year:focus {
  outline: none;
  border-color: theme("colors.slate.200");
  --tw-ring-color: transparent;
}

.litepicker .container__days .day-item {
  color: theme("colors.slate.800");
}

.litepicker .container__days .day-item:hover {
  box-shadow: none;
  background-color: theme("colors.slate.100");
  color: theme("colors.slate.800");
}

.litepicker .container__days .day-item.is-today {
  background-color: theme("colors.secondary");
}

.litepicker .container__days .day-item.is-today:hover {
  font-weight: theme("fontWeight.medium");
  color: theme("colors.slate.700");
}

.litepicker .container__days .day-item.is-start-date,
.litepicker .container__days .day-item.is-start-date:hover,
.litepicker .container__days .day-item.is-end-date,
.litepicker .container__days .day-item.is-end-date:hover {
  background-color: theme("colors.slate.700");
  color: white;
}

.litepicker .container__days .day-item.is-in-range,
.litepicker .container__days .day-item.is-in-range:hover {
  background-color: theme("colors.slate.100");
}

.litepicker .container__days .week-number {
  color: theme("colors.slate.400");
}

@media (max-width: calc(theme("screens.lg") - 1px)) {
  :root {
    --litepicker-day-width: 35px;
  }
}

.dark .litepicker {
  background-color: theme("colors.darkmode.600");
}

.dark .litepicker:before,
.dark .litepicker:after {
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-color: theme("colors.darkmode.600");
}

.dark .litepicker .container__months .button-previous-month:hover {
  background-color: theme("colors.darkmode.400");
}

.dark .litepicker .container__months .button-next-month:hover {
  background-color: theme("colors.darkmode.400");
}

.dark .litepicker .container__months .month-item-header .button-previous-month:hover svg,
.dark .litepicker .container__months .month-item-header .button-next-month:hover svg,
.dark .litepicker .container__months .month-item-header .button-previous-month svg,
.dark .litepicker .container__months .month-item-header .button-next-month svg {
  border-color: theme("colors.slate.200");
}

.dark .litepicker .container__months .month-item-header div > .month-item-name,
.dark .litepicker .container__months .month-item-header div > .month-item-year {
  color: theme("colors.slate.200");
  border-color: theme("colors.darkmode.400");
}

.dark .litepicker .container__days .day-item {
  color: theme("colors.slate.200");
}

.dark .litepicker .container__days .day-item:hover {
  background-color: theme("colors.darkmode.400");
}

.dark .litepicker .container__days .day-item.is-start-date,
.dark .litepicker .container__days .day-item.is-start-date:hover,
.dark .litepicker .container__days .day-item.is-end-date,
.dark .litepicker .container__days .day-item.is-end-date:hover {
  background-color: theme("colors.slate.700");
  color: theme("colors.slate.200");
}

.dark .litepicker .container__days .day-item.is-in-range,
.dark .litepicker .container__days .day-item.is-in-range:hover {
  background-color: theme("colors.darkmode.400");
}

.dark .litepicker .container__days .day-item.is-today,
.dark .litepicker .container__days .day-item.is-today:hover {
  color: theme("colors.slate.200");
}

.dark .litepicker .container__footer {
  border-color: theme("colors.darkmode.400");
}

.dark .litepicker .container__footer .button-cancel {
  background-color: theme("colors.darkmode.400");
  color: theme("colors.slate.300");
}

.dark .litepicker .container__tooltip {
  background-color: theme("colors.darkmode.400");
}

.dark .litepicker .container__tooltip:before,
.dark .litepicker .container__tooltip:after {
  border-top-color: theme("colors.darkmode.600");
}
