.shuriken .side-menu {
  /* Styles for .side-menu go here */
}

@media screen(xl) {
  .shuriken .side-menu--collapsed.side-menu--on-hover .side-menu__divider {
    color: theme("colors.slate.500" / 80%);
  }

  .shuriken .side-menu--collapsed.side-menu--on-hover .side-menu__divider:before {
    opacity: 0;
  }

  .shuriken .side-menu--collapsed.side-menu--on-hover .side-menu__link {
    justify-content: normal;
  }

  .shuriken .side-menu--collapsed.side-menu--on-hover .side-menu__link__title {
    opacity: 1;
  }

  .shuriken .side-menu--collapsed.side-menu--on-hover .side-menu__link__badge {
    opacity: 1;
  }

  .shuriken .side-menu--collapsed.side-menu--on-hover .side-menu__link__chevron {
    opacity: 1;
  }

  .shuriken .side-menu--collapsed .side-menu__divider {
    height: theme("spacing.5");
    position: relative;
    color: transparent;
    transition-property: theme("transitionProperty.colors");
    transition-duration: theme("transitionDuration.100");
  }

  .shuriken .side-menu--collapsed .side-menu__divider:before {
    content: "...";
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    color: theme("colors.slate.500" / 80%);
    transition-property: theme("transitionProperty.opacity");
    transition-duration: theme("transitionDuration.100");
  }

  .shuriken .side-menu--collapsed .side-menu__link {
    overflow: hidden;
  }

  .shuriken .side-menu--collapsed .side-menu__link__title {
    white-space: nowrap;
    opacity: 0;
    transition-property: theme("transitionProperty.opacity");
    transition-duration: theme("transitionDuration.300");
  }

  .shuriken .side-menu--collapsed .side-menu__link__badge {
    opacity: 0;
    transition-property: theme("transitionProperty.opacity");
    transition-duration: theme("transitionDuration.300");
  }

  .shuriken .side-menu--collapsed .side-menu__link__chevron {
    opacity: 0;
    transition-property: theme("transitionProperty.opacity");
    transition-duration: theme("transitionDuration.300");
  }
}

.shuriken .side-menu__divider {
  white-space: nowrap;
  font-size: theme("fontSize.xs");
  color: theme("colors.slate.500");
  margin-top: theme("spacing.5");
  margin-bottom: theme("spacing.3");
}

.shuriken .side-menu__link {
  display: flex;
  align-items: center;
  margin-bottom: theme("spacing.1");
  border-radius: 0.6rem;
  border: 1px solid transparent;
  padding: theme("spacing.3") theme("spacing[3.5]");
  transition-property: theme("transitionProperty.all");
  transition-timing-function: theme("transitionTimingFunction.in");
  transition-duration: theme("transitionDuration.200");
}

.shuriken .side-menu__link--active .side-menu__link__icon {
  opacity: theme("opacity.100");
}

.shuriken .side-menu__link--active .side-menu__link__title {
  color: theme("colors.theme.1");
  font-weight: theme("fontWeight.medium");
}

.shuriken .side-menu__link--active .side-menu__link__chevron {
  color: theme("colors.white");
}

.shuriken .side-menu__link__icon {
  flex: none;
  opacity: theme("opacity.80");
  stroke: theme("colors.theme.1");
  fill: theme("colors.theme.1" / 10%);
}

.shuriken .side-menu__link__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: theme("colors.slate.600");
  margin-left: theme("spacing.3");
}

.shuriken .side-menu__link__badge {
  min-width: 1.15rem;
  height: 1.2rem;
  padding: 0 theme("spacing.2");
  border-radius: theme("borderRadius.full");
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: theme("fontSize.xs");
  margin-left: theme("spacing[2.5]");
  font-weight: theme("fontWeight.medium");
  background-color: theme("colors.theme.1" / 15%);
}

.shuriken .side-menu__link__chevron {
  margin-left: auto;
}

.shuriken .side-menu ul.scrollable {
  position: relative;
}

.shuriken .side-menu ul.scrollable > li > .side-menu__link--active {
  background: linear-gradient(
    to right,
    theme("colors.theme.1" / 95%),
    theme("colors.theme.2" / 95%)
  );
  border-width: 0;
  box-shadow: 0px 2px 3px #0000000b;
}

.shuriken .side-menu ul.scrollable > li > .side-menu__link--active .side-menu__link__icon {
  stroke: theme("colors.white");
  fill: theme("colors.white" / 10%);
}

.shuriken .side-menu ul.scrollable > li > .side-menu__link--active .side-menu__link__title {
  color: theme("colors.white");
}

.shuriken .side-menu ul.scrollable > li > .side-menu__link--active .side-menu__link__badge {
  color: theme("colors.white");
  background-color: theme("colors.white" / 15%);
}

.shuriken .side-menu ul.scrollable > li > .side-menu__link--active-dropdown {
  margin-bottom: theme("spacing[1.5]");
}

.shuriken .side-menu ul.scrollable > li > .side-menu__link:hover:not(.side-menu__link--active) {
  background: theme("colors.white" / 80%);
  border-color: theme("colors.slate.200" / 80%);
  box-shadow: 0px 2px 3px #0000000b;
}

.shuriken .side-menu ul.scrollable > li ul {
  padding: theme("spacing.1") 0;
  background: theme("colors.white");
  border-radius: 0.6rem;
  border: 1px solid theme("colors.slate.200");
  box-shadow: 0px 2px 3px #0000000b;
  margin-bottom: theme("spacing.2");
}

.shuriken .side-menu ul.scrollable > li ul .side-menu__link {
  margin-bottom: 0;
}

.shuriken .side-menu ul.scrollable > li ul ul {
  margin: 0px -1px;
  box-shadow: none;
  border: 1px solid theme("colors.slate.200" / 80%);
}
