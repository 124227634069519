@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/material.css';
@import 'tippy.js/animations/scale.css';

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  @font-face {
    font-family: 'custom-font-1';
    font-style: normal;
    font-weight: normal;
    src: url('assets/fonts/Roboto-Regular-webfont.woff') format('woff');
  }
  @font-face {
    font-family: 'custom-font-2';
    font-style: normal;
    font-weight: normal;
    src: url('assets/fonts/PlayfairDisplay-Regular.otf') format("opentype");
  }
  @font-face {
    font-family: 'custom-font-3';
    font-style: normal;
    font-weight: normal;
    src: url('assets/fonts/Pacifico.ttf') format("truetype");
  }
  @font-face {
    font-family: 'custom-font-4';
    font-style: normal;
    font-weight: normal;
    src: url('assets/fonts/Oswald-Regular.ttf') format("truetype");
  }
  @font-face {
    font-family: 'custom-font-5';
    font-style: normal;
    font-weight: normal;
    src: url('assets/fonts/IndieFlower-webfont.woff') format("woff");
  }
  @font-face {
    font-family: 'custom-font-6';
    font-style: normal;
    font-weight: normal;
    src: url('assets/fonts/Allura-Regular.otf') format("opentype");
  }
}

@layer components {
  .box {
    box-shadow: 0px 3px 5px #0000000b;
    background-color: white;
    border: 1px solid theme("colors.slate.200");
    border-radius: 0.6rem;
    position: relative;

  }

  .box--stacked:before {
    content: "";
    z-index: -1;
    box-shadow: theme("boxShadow.sm");
    height: 100%;
    background-color: theme("colors.slate.50");
    border: 1px solid theme("colors.slate.200");
    position: absolute;
    border-radius: theme("borderRadius.lg");
    left: theme("spacing[3.5]");
    right: theme("spacing[3.5]");
    top: 0;
    margin-top: theme("spacing.3");
  }

  .dark .box {
    background-color: theme("backgroundColor.darkmode.600");
    border-color: theme("colors.darkmode.500");
  }

  .dark .box--stacked:before {
    background-color: theme("colors.darkmode.600" / 70%);
    border-color: theme("colors.slate.500" / 60%);
  }
}

html body {

  letter-spacing: theme("letterSpacing.wide");
  font-size: theme("fontSize.sm");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: theme("fontFamily.inter");
  color: theme("colors.slate.600");
  line-height: theme("lineHeight.5");

}

@layer components {
  .image-fit {
    position: relative;
  }

  .image-fit > img {
    position: absolute;
    object-fit: cover;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

@layer components {
  .scrollbar-hidden::-webkit-scrollbar {
    @apply w-0 bg-transparent;
  }
}

@layer components {
  .typing-dots span {
    @apply opacity-0;
  }

  .typing-dots span:nth-child(1) {
    animation: 1s type-animation infinite 0.33333s;
  }

  .typing-dots span:nth-child(2) {
    animation: 1s type-animation infinite 0.66666s;
  }

  .typing-dots span:nth-child(3) {
    animation: 1s type-animation infinite 0.99999s;
  }

  @keyframes type-animation {
    50% {
      @apply opacity-100;
    }
  }
}

@layer components {
  .zoom-in {
    transition-property: transform, box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
  }

  .zoom-in:hover {
    transform: scale(theme("scale.105"));
    box-shadow: theme("boxShadow.xl");
  }
}

.toastify {
  background: none;
  box-shadow: none;
  padding: 0;
  color: theme("colors.slate.700");
  max-width: calc(100% - 30px);
  @media screen(md) {
    max-width: none;
  }
}

.toastify .toast-close {
  color: transparent;
  position: absolute;
  opacity: 1;
  top: 0;
  bottom: 0;
  right: 0;
  padding-right: theme("spacing.3");
  display: flex;
  align-items: center;
}

.toastify .toast-close:before {
  content: "×";
  display: block;
  color: theme("colors.slate.500");
  font-size: theme("fontSize.3xl");
  margin-top: calc(theme("spacing.[1.5]") * -1);
  font-weight: theme("fontWeight.light");
}


.tippy-box {
  background-color: #fff;
  color: theme("colors.slate.900");
  box-shadow: 0 0 20px 4px #00000026, 0 4px 80px -8px #24282f40, 0 4px 4px -2px #5b5e6926;
}

.tippy-arrow {
  color: #fff;
  background-color: #fff;
}

.lg-thumb-item {
  border: none !important;
}

.onboarding-active {
  overflow: hidden;
}

#curtain {
  display: none;
}

.onboarding-active #curtain {
  display: block;
}

